import { alpha } from "@mui/material";

// THIS IS THE MAIN styles for the project

export const mainTheme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400,
    },
  },

  palette: {

    primary: {
      main: "#AA796C",
      // by removing this material ui creates light and dark version based on main
      // light: "#A67FFB",
      // dark: "#5E3BB7",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#ff5151",
      light: "#ff867e",
      dark: "#c50b28",
      contrastText: "#FFF",
    },
    error: {
      main: "#E73145",
      light: "#FF6A70",
      dark: "#AD001E",
      contrastText: "#FFF",
    },
    border: {
      grey: "#ccc",
      black: "#000000",
    },
    warning: {
      main: "#f4bb50",
      light: "#FFC84C",
      dark: "#BB6900",
      contrastText: "#FFF",
    },
    info: {
      main: "#2EB5C9",
      light: "#6FE7FC",
      dark: "#008598",
      contrastText: "#FFF",
    },
    success: {
      main: "#3BD2A2",
      light: "#78FFD3",
      dark: "#00A073",
      contrastText: "#FFF",
    },
    text: {
      primary: "#000000",
      secondary: "#8595A6",
      disabled: "#e4e4e4",
    },
    divider: "#DEE2E6",
    background: {
      paper: "#FFFFFF",
      default: "#F2F2F2",
      primary: "#1D194A",
      filter: "rgba(170, 121, 108, 0.2)",
      secondary: "#D1D2DB",
      transparent: "transparent",
      black: "#000000",
      green: "#449264"
    },
    analytics: {
      green: "#449164",
      orange: "#D16A51",
      red: "#982715"
    },
    orderStatus: {
      pending: "#EFB762",
      ready: "#1E1A4A",
      completed: "#449264",
      cancelled: "#D4665E",
      confirmed: "#A5F2F3",
      outForDelivery: "#EDFF23",
      deliveredLate: "#D3F156",
      default: "#CCCCCC",
    },
    // custom variables
    calendar: {
      pause: "#AA796C4D",
      offday: "#D9D9D9",
      freeze: "#A5F2F3",
      selected: "#AA796C"
    },
    productStatus: {
      grey: "#00000080",
      red: "#B9212180"
    },
    action: {
      active: "#475259",
      hover: "#F2F2F2",
    },
    table: {
      tertiary: "#E6D7D3",
      carbo: "#CCAFA7",
    },

    button: {
      curve: {
        borderRadius: "25px",
        padding: "6px 9px",
        fontSize: "1.142rem",
        fontWeight: 400,
        lineHeight: "1.357rem",
        textTransform: "none",
      },
    },
    checkBoxColor: "#6563FF",
    listGridIconColor: "rgba(210, 209, 219, 0.7)",


  },
  lightfieldPallate: {
    primary: {
      main: "#AA796C",
      light: "#E2B7A7",
      contrastText: "#FFF",
    },
    background: {
      default: "#E9E9E9",
    },
    text: {
      primary: "#000000",
      secondary: "#8595A6",
      disabled: "#e4e4e4",
      subscriptionCreate: {
        backColor: "#777592",
        packageIconColor: "rgba(29, 25, 74, 0.1)",
      },
    },
    package: {
      cardBorderColor: "rgba(170, 121, 108, 0.5)",
      buttonColor: "rgba(170, 121, 108, 0.2)",
    },
  },

  shadows: [
    "none",
    "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
    "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
    "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
    "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
    "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
    "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
    "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
    "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
    "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
    "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
    "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
    "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
    "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
    "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
    "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
    "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
    "0 5px 10px rgba(0, 0, 0, 0.085)",
    "0 5px 10px rgba(0, 0, 0, 0.175)",
  ],
  typography: {
    fontFamily: "Helvetica Neue",
    fontSize: 14, // NOTE this is not used by the system change public/style.css html if you need to.
    h1: {
      fontSize: "1.5rem",
      lineHeight: 1.2,
      fontWeight: 400,
      color: "#000000",
      margin: "0 0 .5rem",
    },
    h2: {
      fontSize: "1.4rem",
      lineHeight: 1.2,
      fontWeight: 400,
      color: "#37373C",
      margin: "0 0 .5rem",
    },
    h3: {
      fontSize: "1.25rem",
      lineHeight: 1.2,
      fontWeight: 400,
      color: "#37373C",
      margin: "0 0 .5rem",
    },
    h4: {
      fontSize: "1.1rem",
      lineHeight: 1.2,
      fontWeight: 400,
      color: "#37373C",
      margin: "0 0 .5rem",
    },
    h5: {
      fontSize: "1rem",
      lineHeight: 1.2,
      fontWeight: 400,
      color: "#37373C",
      margin: "0 0 .5rem",
    },
    h6: {
      fontSize: ".875rem",
      lineHeight: 1.2,
      fontWeight: 400,
      color: "#37373C",
      margin: "0 0 .5rem",
    },
    body1: {
      fontSize: "1.142rem",
    },
    table: {
      columnHeader: "1.1rem",
      columnItem: "1.1rem",
    },
    sideNav: {
      fontFamily: "Seravek",
      fontWeight: 500,
      fontSize: "1.142rem",
      color: "#1D194A",
      lineHeight: "29px",
    },
    pageHeader: {
      fontSize: "1.714rem",
      fontWeight: 700,
      fontSytle: "normal",
      color: "#000000",
      lineHeight: "2.071rem",
      fontFamily: "Seravek",
    },

  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: alpha("#000", 0.1),
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          minHeight: "auto",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          boxShadow: `0 0.5rem 1.25rem ${alpha("#7352C7", 0.175)}`,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 24,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: "18px 24px",
        },
        title: {
          fontSize: "1.1rem",
          marginBottom: 0,
        },
        subheader: {
          margin: "4px 0 0",
        },
        action: {
          margin: 0,
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: "8px 24px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          height: 22,
          fontSize: 12,
          color: "#4A476E",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          letterSpacing: 1,
        },
        sizeSmall: {
          fontSize: "12px",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: 18,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "16px 24px",
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        avatar: {
          backgroundColor: "#757575",
          fontSize: 16,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "#E3E3E5 !important",
          opacity: "0.4 !important",
        },
      },
    },
    // styles for materialui tab panels

    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: "16px",
          fontWeight: 400,
          textTransform: "capitalize",
          minHeight: "auto",
          minWidth: "auto",
          padding: "7px",

          "&.Mui-selected": {
            color: "#171C19",
          },
        },
        textColorPrimary: {
          color: "#171C19",
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: "auto",
          "& .MuiTabs-indicator": {
            backgroundColor: "#171C19",
            height: "3px",
          },
        },
      },
    },

    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: "24px 0",
        },
      },
    },

    // Override the default styles for the Dialog component

    //  custom components
    customChip: {
      color: "#1E1A4A",
      textTransform: "uppercase",
      fontSize: "1.142rem",
      lineHeight: "20px",
      backgroundColor: "#D2D1DB",
      borderRadius: "2px",
    },
    tabClientProduction: {
      color: "#E9E9E9",
    },
    searchBar: {
      color: "rgba(0, 0, 0, 0.5)",
    },
    clientOrder: {
      rowColor: "#A5A3B7",
    },
    subscription: {
      boxColor: "#799D80",
      mealContentKey: "rgba(0, 0, 0, 0.5)",
      likes: "rgba(165, 163, 183, 0.25)",
      dislikes: "rgba(212, 102, 94, 0.25)",
      dislikeColor: "#D4665E",
      paymentBg: "#F3F3F3",
      darkBgColor: "rgba(30, 26, 74, 0.9)",
      factsColor: "#9C8F8B",
      noMealBoxBg: "rgba(210, 209, 219, 0.3)",
      noMealBg: "rgba(210, 209, 219, 0.2)",
      macrosBg: "rgba(239, 140, 116, 0.05)",
      mealSelectedColor: "#982615",
      proteinColor: "#D2876E",
      carbColor: "#DEA945",
      fatColor: "#C7D4C9",
      mealItemId: "#201D13",
      monthName: "#828282",
      white: "#ffffff",
    },
    shiftComponent: {
      bgColor: "rgb(248, 182, 45, 0.2)",
      color: "#231815",
    },
    selectWithIcon: {
      borderColor: "#EDC4B9",
    },
    meals: {
      customColors: {
        iosColor: "rgba(17, 17, 17, 0.50)",
      },
    },
  },
};
