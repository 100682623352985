import produce from "immer";
import {
  SET_CATALOG_DATA,
  SET_CATALOG_TOKENS,
  SET_CATALOG_LOADING,
  SET_CATALOG_MENU_LOADING,
  SET_ACTIVE_CATALOG_ID,
  SET_CATALOG_MENU,
  SET_CATALOG_ACTIVE_MENU,
  SET_MENU_SELECTED_DAYS,
  SET_CATALOG_MENU_CREATE,
  RESET_MENU_DATA,
  CLEAR_CATALOG_STORE,
  SET_ACTIVE_MENU_ITEMS,
  SET_ACTIVE_MENU_ITEMS_LOADER,
  SET_DELETING_ACTIVE_MENU_ITEMS,
  SET_MEAL_TO_DELETE,
  SET_MEAL_TO_DELETE_LOADER,
  SET_LIST_GRID_VIEW,
} from "app/utils/constants/catalogs";

const initialState = {
  catalogData: null,
  loading: false,
  nextToken: null,
  prevToken: null,
  currentToken: null,
  activeCatalogId: null,
  catalogMenu: null,
  activeCatalogMenu: null,
  catalogMenuLoading: false,
  menuSelectedDays: [],
  activeMenuItems: null,
  deletingActiveMenuItems: false,
  activeMenuItemsLoader: false,
  mealToDelete: [],
  mealToDeleteLoader: false,
  listGridView: "grid",
};

export default produce((draft, action) => {
  switch (action.type) {
    case SET_CATALOG_DATA:
      draft.catalogData = action.payload;
      break;
    case SET_CATALOG_TOKENS:
      draft.nextToken = action.payload.nextToken;
      draft.prevToken = action.payload.prevToken;
      break;
    case SET_CATALOG_LOADING:
      draft.loading = action.payload;
      break;
    case SET_ACTIVE_CATALOG_ID:
      draft.activeCatalogId = action.payload;
      break;
    case SET_CATALOG_MENU:
      draft.catalogMenu = action.payload;
      break;
    case SET_CATALOG_MENU_LOADING:
      draft.catalogMenuLoading = action.payload;
      break;
    case SET_MENU_SELECTED_DAYS:
      draft.menuSelectedDays = action.payload;
      break;
    case SET_CATALOG_ACTIVE_MENU:
      draft.activeCatalogMenu = action.payload;
      break;
    case SET_CATALOG_MENU_CREATE:
      draft.activeCatalogMenu = null;
      draft.menuSelectedDays = [];
      draft.activeMenuItems = null;
      break;
    case SET_ACTIVE_MENU_ITEMS:
      draft.activeMenuItems = action.payload;
      break;
    case SET_DELETING_ACTIVE_MENU_ITEMS:
      draft.deletingActiveMenuItems = action.payload;
      break;
    case SET_ACTIVE_MENU_ITEMS_LOADER:
      draft.activeMenuItemsLoader = action.payload;
      break;
    case SET_MEAL_TO_DELETE:
      draft.mealToDelete = action.payload;
      break;
    case SET_MEAL_TO_DELETE_LOADER:
      draft.mealToDeleteLoader = action.payload;
      break;
    case SET_LIST_GRID_VIEW:
      draft.listGridView = action.payload;
      break;
    case RESET_MENU_DATA:
      draft.activeCatalogMenu = null;
      draft.catalogMenu = null;
      draft.menuSelectedDays = [];
      draft.activeMenuItems = null;

      break;
    case CLEAR_CATALOG_STORE:
      return initialState;
    default:
      return draft;
  }
}, initialState);
