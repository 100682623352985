import {
  ALERT_MEESAGE,
  SET_COUNTRIES,
  SET_PACKAGES,
  SET_BRANDS,
  SET_PAYMENT_PROVIDERS,
  SET_GOVENRATES_AND_DISTRICTS,
  SET_ALL_PREFERENCES,
  SET_PACKAGE_LOADER,
  SET_CUSTOM_PACKAGE,
  SET_CONFIG,
  SET_STORES,
  SET_ALLERGIES_SELECTION,
  SET_BUNDLE_FILTER,
  SET_BUNDLE_DATA,
  SET_BUNDLE_LOADER,
} from "app/utils/constants/common";
import {
  getGoogleMapDataAPI,
  getCountriesAPI,
  getPackagesAPI,
  getBrandsAPI,
  getBundlesAPI,
  getPatmentProvidersAPI,
  getGovenratesAndDistrictsApi,
  getAllPreferencesAPI,
  getConfigurationsAPI,
  getStoresAPI,
  getAllergiesAPI,
} from "app/services/common";
import { errorHandler } from "app/shared/Service/errorHandler";
import { createQuery } from "app/utils/constants/common";

export const alertMessage = (message: any, variant: any) => ({
  type: ALERT_MEESAGE,
  payload: {
    message,
    variant,
  },
});

export const getCountries = () => async (dispatch: any) => {
  try {
    const { data } = await getCountriesAPI();
    dispatch({
      type: SET_COUNTRIES,
      payload: data?.data || [],
    });
  } catch (error) {
    const message = errorHandler(error);
    dispatch(alertMessage(message, "error"));
  }
};

export const getPackages =
  (payload: any, cb?: any) => async (dispatch: any) => {
    try {
      let query = "";
      if (payload) {
        query = "?" + createQuery({ filter: payload });
      }

      const { data } = await getPackagesAPI(query);
      dispatch({
        type: SET_PACKAGES,
        payload: data?.data || [],
      });
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    } finally {
      cb?.();
    }
  };

export const getBrands = () => async (dispatch: any) => {
  try {
    const { data } = await getBrandsAPI();
    dispatch({
      type: SET_BRANDS,
      payload: data?.data || [],
    });
  } catch (error) {
    const message = errorHandler(error);
    dispatch(alertMessage(message, "error"));
  }
};

export const getStores = () => async (dispatch: any) => {
  try {
    const { data } = await getStoresAPI();
    dispatch({
      type: SET_STORES,
      payload: data?.data || [],
    });
  } catch (error) {
    const message = errorHandler(error);
    dispatch(alertMessage(message, "error"));
  }
};

export const getBundles =
  (payload = {}, cb?: any) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: SET_BUNDLE_LOADER,
        payload: true,
      });

      const { bundleFilters } = getState().common;
      const queryParams = { ...bundleFilters, ...payload };
      const customFields = [
        {
          customKey: "brands",
          newValue: "brandId",
          valueType: "array" as const,
        },
      ];
      const query = "?" + createQuery({ filter: queryParams, customFields });

      const { data } = await getBundlesAPI(query);

      // Destructure only the required properties from `data` and set default values
      const { data: bundleData = [], pagination = {}, included } = data || {};

      dispatch({
        type: SET_BUNDLE_DATA,
        payload: {
          bundleData,
          bundleNextToken: pagination.next?.continutationToken ?? null, // Use optional chaining and nullish coalescing instead of `||`
          bundlePrevToken: pagination.previous?.continutationToken ?? null,
          bundleIncluded: included ?? null,
        },
      });

      cb?.(data);
    } catch (error) {
      cb?.(null, error);
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    } finally {
      dispatch({
        type: SET_BUNDLE_LOADER,
        payload: false,
      });
    }
  };

export const applyBundleFilter = (payload: any) => (dispatch: any) => {
  dispatch({
    type: SET_BUNDLE_FILTER,
    payload,
  });
};

export const getGovenratesAndDistricts = () => async (dispatch: any) => {
  try {
    const { data } = await getGovenratesAndDistrictsApi();
    dispatch({
      type: SET_GOVENRATES_AND_DISTRICTS,
      payload: data?.data || [],
    });
  } catch (error) {
    const message = errorHandler(error);
    dispatch(alertMessage(message, "error"));
  }
};

export const getPaymentProviders =
  (storeId: any, cb?: any) => async (dispatch: any) => {
    try {
      const { data } = await getPatmentProvidersAPI(storeId);
      dispatch({
        type: SET_PAYMENT_PROVIDERS,
        payload: data?.data || [],
      });
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    } finally {
      cb?.();
    }
  };

export const getAllPreferences = (cb?: any) => async (dispatch: any) => {
  try {
    const { data } = await getAllPreferencesAPI();
    dispatch({
      type: SET_ALL_PREFERENCES,
      payload: data?.data || [],
    });
  } catch (error) {
    const message = errorHandler(error);
    dispatch(alertMessage(message, "error"));
  } finally {
    cb?.();
  }
};

export const getGoogleMapData =
  (lat?: any, lng?: any, cb?: any) => async () => {
    try {
      const response = await getGoogleMapDataAPI(lat, lng);
      const data = await response.json();
      cb?.(lat, lng, data);
    } catch (error) {
      cb?.(lat, lng, null);
    }
  };

export const getCustomPackages =
  (payload?: any, cb?: any) => async (dispatch: any) => {
    try {
      let query = "";
      if (payload) {
        query = "?" + createQuery({ filter: payload });
      }

      dispatch({
        type: SET_PACKAGE_LOADER,
        payload: true,
      });

      const { data } = await getPackagesAPI(query);
      dispatch({
        type: SET_CUSTOM_PACKAGE,
        payload: {
          data: data?.data || [],
          nextToken: data?.pagination?.next?.continutationToken || null,
          prevToken: data?.pagination?.previous?.continutationToken || null,
        },
      });
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    } finally {
      dispatch({
        type: SET_PACKAGE_LOADER,
        payload: false,
      });
      cb?.();
    }
  };

export const getConfiguration =
  (payload?: any) => async (dispatch: any, getState: any) => {
    try {
      const { locale, application } = payload;
      const { common } = getState();
      const currentConfig = common?.configurations || {};

      // values of specific locale
      const configLocale = currentConfig?.[locale] || {};
      const {
        data: { data },
      } = await getConfigurationsAPI(payload);
      const appData = { ...configLocale, [application]: data };
      const finalData = { ...currentConfig, [locale]: appData };

      dispatch({
        type: SET_CONFIG,
        payload: finalData,
      });
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };

export const getAllergies = (cb?: any) => async (dispatch: any) => {
  try {
    const { data } = await getAllergiesAPI();
    dispatch({
      type: SET_ALLERGIES_SELECTION,
      payload: data?.data || [],
    });
    cb?.();
  } catch (error) {
    const message = errorHandler(error);
    dispatch(alertMessage(message, "error"));
  }
};
