import React, { Suspense } from "react";
import { IconButton, Typography } from "@mui/material";
import menus from "./menus";
import JumboVerticalNavbar from "@jumbo/components/JumboVerticalNavbar/JumboVerticalNavbar";
import { DrawerHeader } from "@jumbo/components/JumboLayout/style";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import useJumboSidebarTheme from "@jumbo/hooks/useJumboSidebarTheme";
import { SIDEBAR_STYLES, SIDEBAR_VIEWS } from "@jumbo/utils/constants/layout";

import Zoom from "@mui/material/Zoom";
import Div from "@jumbo/shared/Div";
import SidebarSkeleton from "./SidebarSkeleton";
import Humburger from "../../../../../images/humburgerO.svg";
import { useNavigate } from "react-router";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";

const Sidebar = () => {
  const { authUser } = useJumboAuth();
  const filteredMenus = menus?.filter((menu) =>
    menu.privilege.includes(authUser?.data.privilege)
  );

  return (
    <React.Fragment>
      <SidebarHeader />
      <JumboScrollbar autoHide autoHideDuration={200} autoHideTimeout={500}>
        <Suspense
          fallback={
            <Div
              sx={{
                display: "flex",
                minWidth: 0,
                alignItems: "center",
                alignContent: "center",
                px: 3,
              }}
            >
              <SidebarSkeleton />
            </Div>
          }
        >
          <JumboVerticalNavbar translate items={filteredMenus} />

        </Suspense>
      </JumboScrollbar>
      <SidebarFooter />
    </React.Fragment>
  );
};

const SidebarFooter = () => {
  const { setAuthToken, authUser } = useJumboAuth();
  const navigate = useNavigate();
  const onLogout = () => {
    setAuthToken(null);
    navigate("/auth/login");

    const keyExists = localStorage.getItem("authUser") !== null;

    if (keyExists) {
      localStorage.removeItem("authUser");
    }
  };

  return (
    <Div sx={{ p: 3 }}>
      <Typography
        onClick={onLogout}
        variant="button"
        sx={{ color: "text.primary", cursor: "pointer" }}
      >
        Logout
      </Typography>
      <Typography variant="body2" sx={{ color: "text.primary", mt: 2 }}>
        {authUser?.data?.privilege || "Admin"}
      </Typography>
      <Typography variant="body2" sx={{ color: "text.primary", mt: 1 }}>
        {authUser?.data?.name || "-"}
      </Typography>
      <Div
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: 1,
        }}
      >
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {authUser?.data?.email || "-"}
        </Typography>
      </Div>
      <Typography variant="body2" sx={{ color: "text.secondary", mt: 3 }}>
        Version 1.1.2 &#169; Ligthfields{" "}
      </Typography>
    </Div>
  );
};

const SidebarHeader = () => {
  const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
  // eslint-disable-next-line no-unused-vars
  const { sidebarTheme } = useJumboSidebarTheme();

  // eslint-disable-next-line no-unused-vars
  const isMiniAndClosed = React.useMemo(() => {
    return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
  }, [sidebarOptions.view, sidebarOptions.open]);

  return (
    <React.Fragment>
      {sidebarOptions?.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER && (
        <DrawerHeader>
          {sidebarOptions?.view !== SIDEBAR_VIEWS.MINI && (
            <Zoom in={sidebarOptions?.open}>
              <IconButton
                edge="start"
                color="inherit"
                sx={{
                  ml:
                    sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER
                      ? -2
                      : 0,
                  mr: 1,
                  backgroundColor: "transparent",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                onClick={() => setSidebarOptions({ open: false })}
              >
                <img src={Humburger} alt="humburger" />
              </IconButton>
            </Zoom>
          )}
        </DrawerHeader>
      )}
    </React.Fragment>
  );
};

export default Sidebar;
