// @ts-nocheck
import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";

type props = {
  loading: boolean;
  label: string;
};

const AuthButton = ({ loading, label = "Submit" }: props) => {
  const { theme }: any = useJumboTheme();
  return (
    <LoadingButton
      fullWidth
      type="submit"
      variant="contained"
      size="large"
      sx={{
        mb: 3,
        backgroundColor: theme.palette.secondary.contrastText,
        color: theme.palette.primary.main,
        maxWidth: 130,
        "&:hover": {
          backgroundColor: theme.palette.secondary.contrastText,
          color: theme.palette.primary.main,
        },
        "&:disabled": {
          backgroundColor: theme.palette.secondary.contrastText,
        },
      }}
      loading={loading}
    >
      {label}
    </LoadingButton>
  );
};

AuthButton.propTypes = {};

export default AuthButton;
