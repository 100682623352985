import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

const LanguageDropdown = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("KW");

  useEffect(() => {
    const countryLocalerString = localStorage.getItem("countryCode");
    if (countryLocalerString) {
      setSelectedLanguage(countryLocalerString);
    }
  }, []);

  const onLanguageChange = (event: any) => {
    const value = event.target.value;
    localStorage.setItem("countryCode", value);
    setSelectedLanguage(value);
  };

  return (
    <FormControl size="small">
      <Select
        labelId="language-label"
        id="language-select"
        value={selectedLanguage}
        onChange={onLanguageChange}
      >
        {countries.map((language) => (
          <MenuItem key={language.code} value={language.code}>
            {language.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default React.memo(LanguageDropdown);

interface CountryType {
  code: string;
  label: string;
  phone?: string;
  suggested?: boolean;
}

const countries: readonly CountryType[] = [
  { code: "KW", label: "KW" },
  { code: "SA", label: "SA" },
];
