import produce from "immer";
import {
  SET_ADDRESS_LIST,
  SET_ADDRESS_LOADING,
} from "app/utils/constants/address";

const initialState = {
  address_list: [],
  addressLoading: null,
};

export default produce((draft, action) => {
  switch (action.type) {
    case SET_ADDRESS_LIST:
      draft.address_list = action.payload;
      break;
    case SET_ADDRESS_LOADING:
      draft.addressLoading = action.payload;
      break;
    default:
      return draft;
  }
}, initialState);
