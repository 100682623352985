const { createTheme } = require("@mui/material");
export const InputWhite = () =>
  createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& label": {
              color: "#fff",
            },
            "& label.Mui-focused": {
              color: "#fff",
            },
            "& label.Mui-error": {
              color: "#ff5151",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "#fff",
            },
            "input:-internal-autofill-selected ": {
              backgroundColor: "none!important",
            },
            "input:-webkit-autofill": {
              WebkitBoxShadow:
                "0 0 0 50px #1D194A inset" /* Change the color to your own background color */,
              WebkitTextFillColor: "#fff",
            },

            "input:-webkit-autofill:focus": {
              WebkitBoxShadow: "0 0 0 50px #1D194A inset" /*your box-shadow*/,
              WebkitTextFillColor: "#fff",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#fff",
              },
              "&:hover fieldset": {
                borderColor: "#fff",
                borderWidth: "0.15rem",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#fff",
              },
              // error state
              "&.Mui-error fieldset": {
                borderColor: "#ff5151",
              },
            },
            // input color
            "& .MuiInputBase-input": {
              color: "#fff",
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: "#fff",
          },
          colorSecondary: {
            "&.Mui-checked": {
              color: "#fff",
            },
          },
        },
      },
    },

    overrides: {
      MuiOutlinedInput: {
        input: {
          "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 100px #266798 inset",
            WebkitTextFillColor: "#fff",
          },
        },
      },
    },
  });
