export const SET_CLIENTS_LIST = "SET_CLIENTS_LIST";
export const SET_CLIENTS_WAITING_LIST = "SET_CLIENTS_WAITING_LIST";
export const SET_CLIENTS_CURRENT_PAGE = "SET_CLIENTS_CURRENT_PAGE";
export const SET_CLIENTS_NEXT_PAGE = "SET_CLIENTS_NEXT_PAGE";
export const SET_CLIENTS_PREV_PAGE = "SET_CLIENTS_PREV_PAGE";
export const SET_CLIENTS_PAGE = "SET_CLIENTS_PAGE";
export const SET_CLIENTS_FILTER = "SET_CLIENTS_FILTER";
export const SET_CLIENTS_LOADING = "SET_CLIENTS_LOADING";
export const CLEAR_CLIENTS_STORE = "CLEAR_CLIENTS_STORE";
export const SET_ALLERGIES = "SET_ALLERGIES";
export const SET_DISLIKES = "SET_DISLIKES";
export const SET_LIKES = "SET_LIKES";
export const SET_VISIT_DATA = "SET_VISIT_DATA";
export const SET_VISIT_DATA_LOADER = "SET_VISIT_DATA_LOADER";
export const SET_VISIT_FILTER = "SET_VISIT_FILTER";
export const SET_CLIENT_VISIT_DETAIL = "SET_CLIENT_VISIT_DETAIL";
export const SET_CLIENT_DETAILS = "SET_CLIENT_DETAILS";
export const SET_DIETICIAN = "SET_DIETICIAN";
export const SET_SCHED_VISIT_DATA = "SET_SCHED_VISIT_DATA";
export const SET_SCHED_VISIT_LOADER = "SET_SCHED_VISIT_LOADER";
export const SET_SCHED_VISIT_FILTER = "SET_SCHED_VISIT_FILTER";
export const SET_SCHED_VISIT_DETAIL = "SET_SCHED_VISIT_DETAIL";
export const SET_STORE_ANALYTICS = "SET_STORE_ANALYTICS";
