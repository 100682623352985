import React from "react";
import Page from "@jumbo/shared/Page";
import Login from "../pages/auth/login";
const Signup = React.lazy(() => import("../pages/auth/signup"));
const ForgotPassword = React.lazy(() => import("../pages/auth/forgotPassword"));
const AskForAccess = React.lazy(() => import("../pages/auth/askForAccess"));
const ResetPassword = React.lazy(() => import("../pages/auth/resetPassword"));

export const authRoutes = [
    {
        path: "/auth/login",
        element: <Page component={Login} layout={"solo-page"} />
    },
    {
        path: "/auth/signup",
        element: <Page component={Signup} layout={"solo-page"} />
    },
    {
        path: "/auth/forgot-password",
        element: <Page component={ForgotPassword} layout={"solo-page"} />
    },
    {
        path: "/auth/ask-for-access",
        element: <Page component={AskForAccess} layout={"solo-page"} heading="Ask for Access" />
    },
    {
        path: "/auth/invite",
        element: <Page component={AskForAccess} layout={"solo-page"} heading="Invite User" />
    },
    {
        path: "/auth/reset-password",
        element: <Page component={ResetPassword} layout={"solo-page"} />
    }
]