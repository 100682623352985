export const configurationsStaging: BackendConfigurations = {
  kw: {
    CountryId: 8,
    CurrencyId: 7,
    defaultProvinceId: 18,
    defaultBrandId: 1,
    brands: [
      {
        brandId: 3,
        brandName: "EatWell",
        application: "EatWell",
        brandIconUrl: "",
        storeid: 1,
        storeName: "Eatwell-KW",
      },
      {
        brandId: 1,
        brandName: "Numou",
        application: "Numou",
        brandIconUrl: "",
        storeid: 2,
        storeName: "Numou-KW",
      },
    ],
  },
  sa: {
    CountryId: 10,
    CurrencyId: 10,
    defaultProvinceId: 19,
    defaultBrandId: 1,
    brands: [
      {
        brandId: 1,
        brandName: "Numou",
        application: "Numou",
        brandIconUrl: "",
        storeid: 3,
        storeName: "Numou-SA",
      },
    ],
  },
};

export const configurationsProduction: BackendConfigurations = {
  kw: {
    CountryId: 1,
    CurrencyId: 1,
    defaultProvinceId: 1,
    defaultBrandId: 2,
    brands: [
      {
        brandId: 1,
        brandName: "EatWell",
        application: "EatWell",
        brandIconUrl: "",
        storeid: 1,
        storeName: "Eatwell - KW",
      },
      {
        brandId: 2,
        brandName: "Numou",
        application: "Numou",
        brandIconUrl: "",
        storeid: 2,
        storeName: "Numou-KW",
      },
    ],
  },
  sa: {
    CountryId: 2,
    CurrencyId: 2,
    defaultProvinceId: 2,
    defaultBrandId: 2,
    brands: [
      {
        brandId: 2,
        brandName: "Numou",
        application: "Numou",
        brandIconUrl: "",
        storeid: 3,
        storeName: "Numou-SA",
      },
    ],
  },
};

export interface BackendConfigurations {
  kw: BackendCountryConfigurations;
  sa: BackendCountryConfigurations;
}

export interface BackendCountryConfigurations {
  CountryId: number;
  CurrencyId: number;
  defaultProvinceId: number;
  defaultBrandId: number;
  brands: {
    brandId: number | string;
    brandName: string;
    application: string;
    brandIconUrl: string;
    storeid: number;
    storeName: string;
  }[];
}
