
import { ProductStatusData, productMenuList, sectionsData,navigateCategoriesList, SectionById, GetProductById } from "app/types";
import { SET_PRODUCTS_DATA, SET_PRODUCT_BY_ID, SET_PRODUCT_CATEGORIES_LIST, SET_PRODUCT_FILTER, SET_PRODUCT_LIST_LOADING, SET_PRODUCT_STATUS, SET_SECTIONS, SET_SECTIONS_BY_ID } from "app/utils/constants/products";
import produce from "immer";
interface ConfigurationInitState {
  productMenuList: productMenuList[],
  loading : boolean,
  nextPage: number | null,
  prevPage: number | null,
  productCategoryList:navigateCategoriesList[],
  filter: any;
  productStatus:ProductStatusData,
  productSections:sectionsData[],
  sectionById:SectionById,
  productById:GetProductById
}
const initialState: ConfigurationInitState = {
  productMenuList: null,
  loading:null,
  nextPage: null,
  sectionById:null,
  filter: null,
  prevPage:null,
  productById:null,
  productCategoryList:null,
  productStatus:null,
  productSections:null
};

export default produce((draft, action) => {
  switch (action.type) {
    case SET_PRODUCTS_DATA:
      draft.productMenuList= action.payload;
       break;
      case SET_PRODUCT_LIST_LOADING:
        draft.loading = action.payload;
        break;
        case SET_PRODUCT_CATEGORIES_LIST:
        draft.productCategoryList = action.payload;
        break;
        case SET_PRODUCT_STATUS:
          draft.productStatus = action.payload;
          break;
        case SET_PRODUCT_FILTER:
          draft.filter = action.payload;
          break;
          case SET_SECTIONS:
          draft.productSections = action.payload;
          break;
          case SET_SECTIONS_BY_ID:
          draft.sectionById = action.payload;
          break;
          case SET_PRODUCT_BY_ID:
            draft.productById = action.payload;
            break;
    default:
      return draft;
  }
}, initialState);
