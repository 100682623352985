export const errorHandler = (error: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  if (error.response) {
    const { status, data } = error.response;
    if (status === 401) {
      return "Not Authorized, Please make sure you have correct permissions. Try to logout and log back in.";
    } else if (status === 404) {
      const message = data?.error?.message || "Not Found";
      return message;
    } else if (status === 500) {
      return "Internal Server Error";
    } else if (data?.Errors?.length > 0) {
      const message = data?.Errors[0]?.Message || "Something went wrong";
      return message;
    } else if (data?.errors) {
      //@ts-ignore
      const message = Object.values(data?.errors)[0][0];
      return message || "Something went wrong";
    } else {
      const message =
        data?.message || data?.error?.message || "Something went wrong";
      return message;
    }
  } else if (error.request) {
    return "Something went wrong";
  } else {
    return "Something went wrong";
  }
};
