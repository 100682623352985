import { authRoutes } from "./auth";
import { routesForAuthenticatedOnly as authenticatedOnly } from "./routesForAuthenticatedOnly";
/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [

];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
    ...authenticatedOnly
];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
    ...authRoutes

];


const routes = [
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...routesForNotAuthenticatedOnly,
];

export { routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly };