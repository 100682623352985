import React from "react";
import { useField } from "formik";
import TextField from "@mui/material/TextField";

const JumboTextField = (props: any) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <TextField
      sx={{ mt: 1 }}
      {...props}
      {...field}
      helperText={errorText}
      error={!!errorText}
      autoComplete="off"
      onChange={(event) => {
        field.onChange(event);
        props.onChange && props.onChange(event); // Call the original onChange handler (if defined)
      }}
    />
  );
};

export default JumboTextField;
