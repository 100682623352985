import React, { useState, useEffect } from "react";
import { Alert, IconButton, Snackbar } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { alertMessage } from "app/redux/actions/common";

const AlertMessage = () => {
  const dispatch = useDispatch();
  const { message, variant } = useSelector(({ common }) => common);
  const erroranchor = { horizontal: "right", vertical: "top" };
  const successanchor = { horizontal: "right", vertical: "top" };
  const [open, setOpen] = useState(false);
  const [timerId, setTimerId] = useState(null);

  useEffect(() => {
    if (message) {
      setOpen(true);
      const id = setTimeout(() => {
        setOpen(false);
        dispatch(alertMessage("", ""));
      }, 60000);
      setTimerId(id);
    }
  }, [dispatch, message]);

  const handleClose = () => {
    setOpen(false);
    dispatch(alertMessage("", ""));
    clearTimeout(timerId);
  };

  if (message && variant) {
    return (
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={variant === "error" ? erroranchor : successanchor}
        onClose={handleClose}
        sx={{
          minWidth: "40%",
          maxWidth: "60%",
        }}
      >
        <Alert
          severity={variant}
          sx={{ width: "100%" }}
          action={
            <IconButton
              color="inherit"
              size="small"
              onClick={handleClose}
              sx={{ p: 0.5 }}
            >
              <Close fontSize="small" />
            </IconButton>
          }
          variant="filled"
        >
          {message}
        </Alert>
      </Snackbar>
    );
  }
  return null;
};

export default AlertMessage;
