// @ts-nocheck
import produce from "immer";
import {
  GET_DELIVERY_ORDERS,
  SET_DELIVERY_FILTER,
  SET_DELIVERY_LOADING,
} from "app/utils/constants/deliveryOrder";

const initialState = {
  delivery_orders: [],
  zones: null,
  filters: null,
  loading: false,
};

export default produce((draft, action) => {
  switch (action.type) {
    case GET_DELIVERY_ORDERS:
      draft.delivery_orders = action.payload.orders;
      draft.zones = action.payload.zones;

      break;
    case SET_DELIVERY_FILTER:
      draft.filters = action.payload;
      break;
    case SET_DELIVERY_LOADING:
      draft.loading = action.payload;
      break;
    default:
      return draft;
  }
}, initialState);
