import axios from "axios";

const { REACT_APP_AUTH_URL } = process.env;

const AuthAxios = axios.create({
  baseURL: REACT_APP_AUTH_URL,
  headers: {
    "Content-Type": "application/json",
    Application: "dashboard",
  },
});

// Add a response interceptor
AuthAxios?.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
    }
    return Promise.reject(error?.response?.data);
  }
);

export default AuthAxios;
