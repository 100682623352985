import thunk from "redux-thunk";
import { createBrowserHistory, History } from "history";
import { routerMiddleware } from "connected-react-router";
import reducers from "../reducers";
import { configureStore } from "@reduxjs/toolkit";

export const history: History = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
function configureAppStore(initialState = {}) {
  const store = configureStore({
    reducer: reducers(history),
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat([routeMiddleware, thunk]),
  });

  // @ts-ignore
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    // @ts-ignore
    module.hot.accept("../reducers/index", () => {
      const exportReducers = require("../reducers");
      store.replaceReducer(exportReducers);
    });
  }
  return store;
}

export const store = configureAppStore();
export default configureAppStore;
export type AppDispatch = typeof store.dispatch;
