export const SET_EXPRESS_ORDER_LIST = "SET_EXPRESS_ORDER_LIST";
export const SET_EXPRESS_ORDER_PAGE = "SET_EXPRESS_ORDER_PAGE";
export const CLEAR_EXPRESS_ORDER_STORE = "CLEAR_EXPRESS_ORDER_STORE";
export const SET_EXPRESS_ORDER_FILTER = "SET_EXPRESS_ORDER_FILTER";
export const SET_VIEW_ORDER_SUMMARY = "SET_VIEW_ORDER_SUMMARY";
export const NOTIFY_NEW_DATA = "NOTIFY_NEW_DATA";
export const SET_EXPRESS_ORDER_LOADING = "SET_EXPRESS_ORDER_LOADING";
export const SET_EXPRESS_ORDER_STATUS_SUMMARY =
  "SET_EXPRESS_ORDER_STATUS_SUMMARY";
export const SET_EXPRESS_ORDER_MEALS_SUMMARY =
  "SET_EXPRESS_ORDER_MEALS_SUMMARY";
export const SET_BRANCH = "SET_BRANCH"
export const ExpressOrderStatus = {
  Pending: "Pending",
  Confirmed: "Confirmed",
  ReadyForDelivery: "ReadyForDelivery",
  Delivered: "Delivered",
  DeliveredLate: "DeliveredLate",
  Cancelled: "Cancelled",
};
export const UPDATE_EXPRESS_ORDER_ITEM = "UPDATE_EXPRESS_ORDER_ITEM";
export const ORDER_CHECK_INTERVAL = 3600000; // 60 mins