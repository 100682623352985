import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import App from "./app/App";
import "./app/config/i18n";
// import * as Sentry from "@sentry/react";

// Note we only used sentry during staging and production
/*
if (window.location.hostname !== "localhost") {
  const { REACT_APP_SENTRY_LIVE } = process.env;

  const sentryConfigs = {
    staging: {
      url: "https://11bfd4ff4c754c11b3cc6a51b5876537@o4505378839920640.ingest.sentry.io/4505378852634624",
      tracePropagationTargets: [/^https:\/\/stage.dashboard.lightfields.co/],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0,
    },
    production: {
      url: "https://4f323a7f867a4b7e9a62a172023d34d2@o4505378839920640.ingest.sentry.io/4505378858401792",
      tracePropagationTargets: [/^https:\/\/dashboard.lightfields.co/],
      tracesSampleRate: 0.5,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0,
    },
  };

  Sentry.init({
    dsn: sentryConfigs[REACT_APP_SENTRY_LIVE].url,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets:
          sentryConfigs[REACT_APP_SENTRY_LIVE].tracePropagationTargets,
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: sentryConfigs[REACT_APP_SENTRY_LIVE].tracesSampleRate, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate:
      sentryConfigs[REACT_APP_SENTRY_LIVE].replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate:
      sentryConfigs[REACT_APP_SENTRY_LIVE].replaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  // add custom filter to be only reported on sentry if needed
  function customErrorFilter(event) {
    // Exclude events with the string "getVisibleRange" in the error message
    if (event.exception?.values?.[0]?.value?.includes("getVisibleRange")) {
      return null; // Return null to exclude the event
    }

    return event;
  }

  Sentry.addGlobalEventProcessor(customErrorFilter);
}
*/

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
