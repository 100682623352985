import produce from "immer";
import {
  SET_CLIENT_ORDER_DATA,
  SET_CLIENT_ORDER_FILTER,
  SET_CLIENT_ORDER_LOADING,
  SET_CLIENT_PRODUCTION_ORDER_DATA,
  SET_CLIENT_PRODUCTION_ORDER_LOADING,
  SET_ACTIVE_CLIENT_ORDER,
  CLEAR_CLIENT_ORDER_STORE,
} from "app/utils/constants/clientOrder";

const initialState = {
  clientOrderData: null,
  clientOrderPagingData: null,
  activeClientOrder: null,
  clientProductionOrderData: null,
  clientProductionOrderLoading: false,
  loading: false,
  filter: null,
};

export default produce((draft, action) => {
  switch (action.type) {
    case SET_CLIENT_ORDER_DATA:
      draft.clientOrderData = action.payload.data;
      draft.clientOrderPagingData = action.payload.pagination;
      break;
    case SET_CLIENT_ORDER_LOADING:
      draft.loading = action.payload;
      break;
    case SET_CLIENT_ORDER_FILTER:
      draft.filter = action.payload;
      break;
    case SET_CLIENT_PRODUCTION_ORDER_DATA:
      draft.clientProductionOrderData = action.payload;
      break;
    case SET_CLIENT_PRODUCTION_ORDER_LOADING:
      draft.clientProductionOrderLoading = action.payload;
      break;
    case SET_ACTIVE_CLIENT_ORDER:
      draft.activeClientOrder = action.payload;
      break;
    case CLEAR_CLIENT_ORDER_STORE:
      return initialState;
    default:
      return draft;
  }
}, initialState);
