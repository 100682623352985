import produce from "immer";
import {
  SET_CONSULTATION_LIST,
  SET_CONSULTATION_LOADING,
  SET_CONSULTATION_PAGE,
  CLEAR_CONSULTATION_STORE,
  SET_CONSULTATION_FILTER,
} from "app/utils/constants/consultation";

const initialState = {
  consultationList: null,
  nextPage: null,
  prevPage: null,
  loading: false,
  filter: null,
};

export default produce((draft, action) => {
  switch (action.type) {
    case SET_CONSULTATION_LIST:
      return { ...draft, consultationList: action.payload };
    case SET_CONSULTATION_PAGE:
      return {
        ...draft,
        nextPage: action.payload.nextPage,
        prevPage: action.payload.prevPage,
      };
    case SET_CONSULTATION_LOADING:
      return { ...draft, loading: action.payload };
    case SET_CONSULTATION_FILTER:
      return { ...draft, filter: action.payload };

    case CLEAR_CONSULTATION_STORE:
      return initialState;
    default:
      return draft;
  }
}, initialState);
