import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { getBranchSalesAPI, getOverallSalesAPI } from "app/services/analytics";
import { AppDispatch } from "../store";
import { alertMessage } from "../actions/common";
import i18n from "app/config/i18n";
import { errorHandler } from "app/shared/Service/errorHandler";
import { createQuery } from "app/utils/constants/common";

interface InitialState {
  dailyOrders: any | null;
}

const initialState: InitialState = {
  dailyOrders: null,
};

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    setDailyOrdersData: (state, action: PayloadAction<any>) => {
      state.dailyOrders = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setDailyOrdersData } = analyticsSlice.actions;

export default analyticsSlice.reducer;

export const overAllSales = (
  storeId: number,
  payload?: any,
  cb?: Function,
  errorCb?: Function
) => {
  return async (dispatch: AppDispatch) => {
    try {
      let query = "";
      if (payload) {
        query = "?" + createQuery({ filter: payload });
      }
      const { data } = await getOverallSalesAPI(storeId, query);
      dispatch(alertMessage(i18n.t("Updated successfully"), "success"));
      cb?.(data?.data);
    } catch (err) {
      errorCb?.();
      const message = errorHandler(err);
      dispatch(alertMessage(message, "error"));
    }
  };
};

export const branchSales = (
  storeId: number,
  payload?: any,
  cb?: Function,
  errorCb?: Function
) => {
  return async (dispatch: AppDispatch) => {
    try {
      let query = "";
      if (payload) {
        query = "?" + createQuery({ filter: payload });
      }
      const { data } = await getBranchSalesAPI(storeId, query);
      dispatch(alertMessage(i18n.t("Updated successfully"), "success"));
      cb?.(data?.data);
    } catch (err) {
      errorCb?.();
      const message = errorHandler(err);
      dispatch(alertMessage(message, "error"));
    }
  };
};
