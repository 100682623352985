export const SET_COUNTRIES_LIST = "SET_COUNTRIES_LIST";
export const SET_ZONES_LIST = "SET_ZONES_LIST";
export const SET_DELIVERY_AREA_LIST = "SET_DELIVERY_AREA_LIST";
export const SET_CONFIGURATION_FILTER = "SET_CONFIGURATION_FILTER";
export const SET_STOCKS_FILTER="SET_STOCKS_FILTER";
export const SET_CONFIG_LOADING = "SET_CONFIG_LOADING"
export const SET_CONFIG_PAGE= "SET_CONFIG_PAGE"
export const SET_ZONES ="SET_ZONES"
export const SET_PROVINCE = "SET_PROVINCE"
export const SET_GOVERNATE = "SET_GOVERNATE"
export const SET_PRODUCTS = "SET_PRODUCTS"
export const SET_FILTERERD_AREA_LIST = "SET_FILTERERD_AREA_LIST"
export const SET_FILTERERD_ZONE_LIST = "SET_FILTERERD_ZONE_LIST"
export const SET_SEARCH_FILTERERD_AREA_LIST ="SET_SEARCH_FILTERERD_AREA_LIST"
export const SET_SEARCH_FILTERERD_ZONE_LIST ="SET_SEARCH_FILTERERD_ZONE_LIST"
export const SET_FILTERERD_PRODUCT_LIST = "SET_FILTERERD_PRODUCT_LIST"
export const SET_STOCKS_LOADING = "SET_STOCKS_LOADING"
export const SET_STOCKS_CURRENT_PAGE = "SET_STOCKS_CURRENT_PAGE"
export const SET_STOCKS_PAGING = "SET_STOCKS_PAGING"
export const SET_STOCKS_NEXT_PAGE = "SET_STOCKS_NEXT_PAGE"
export const SET_STOCKS_PREV_PAGE = "SET_STOCKS_PREV_PAGE"

