import React from "react";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";

// import SearchGlobal from "../../../../shared/SearchGlobal";
import {
  IconButton,
  Typography,
  // useMediaQuery
} from "@mui/material";
import Div from "@jumbo/shared/Div";

import { SIDEBAR_STYLES, SIDEBAR_VARIANTS } from "@jumbo/utils/constants";
import Humburger from "../../../../../images/humburgerW.svg";
import CountrySelect from "app/common/CountrySelect/CountrySelect";

const Header = () => {
  const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();

  return (
    <Div
      sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
    >
      {(sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER ||
        sidebarOptions.variant === SIDEBAR_VARIANTS.TEMPORARY ||
        (sidebarOptions.variant === SIDEBAR_VARIANTS.PERSISTENT &&
          !sidebarOptions.open)) && (
        <Div sx={{ display: "flex" }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{
              ml:
                sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER
                  ? -2
                  : 0,
              mr: 1,
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
            onClick={() => setSidebarOptions({ open: !sidebarOptions.open })}
          >
            <img src={Humburger} alt="humburger" />
          </IconButton>
          <Typography
            variant="h4"
            noWrap
            sx={{
              color: (theme) => theme.palette.primary.contrastText,
              alignSelf: "center",
              mb: 0,
              fontFamily: "Seravek",
              fontWeight: 400,
              fontSize: "1.142rem",
              lineHeight: "20px",
            }}
          >
            Light Fields
          </Typography>
        </Div>
      )}

      <CountrySelect />
    </Div>
  );
};

export default Header;
