import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../store";
import { alertMessage } from "../actions/common";
import i18n from "app/config/i18n";
import { errorHandler } from "app/shared/Service/errorHandler";
import { createQuery, getActiveConfig } from "app/utils/constants/common";
import {
  DeliveryAreaList,
  DistributionDeliveriesInterface,
  DistributionDriver,
  DistributionExceptions,
  DistributionLocation,
  DistributionStatusGroup,
  DistributionTask,
  DistributionTaskDetails,
  Zones,
} from "app/types";
import {
  getDeliveryTaskDetailsById,
  getDeliveryTasks,
} from "app/services/distribution";
import { getDeliveryAreaAPI } from "app/services/configuration";
import { RootState } from "../reducers/hooks";

interface DistributionFilters {
  zoneIds: number;
  districtIds: number;
  shiftIds: number;
  search: string;
  deliveryDate: string;
  countryId: number;
  customerPhoneNumber: number;
  isStatusGroup: boolean;
}
interface InitialState {
  statusGroups: DistributionStatusGroup[];
  zones: Zones[];
  isTaskDetailsLoading: boolean;
  districtList: DeliveryAreaList[];
  filters: DistributionFilters | {};
  distributionListLoading: boolean;
  distributionListPage: number;
  distributionListNextPage: boolean;
}

const initialState: InitialState = {
  statusGroups: [],
  zones: [],
  districtList: [],
  isTaskDetailsLoading: false,
  filters: null,
  distributionListLoading: false,
  distributionListPage: 0,
  distributionListNextPage: true,
};

export const distributionSlice = createSlice({
  name: "distribution",
  initialState,
  reducers: {
    setDeliveryTasks: (
      state,
      action: PayloadAction<DistributionStatusGroup[]>
    ) => {
      state.statusGroups = action.payload;
    },

    setDeliveryTasksZones: (state, action: PayloadAction<Zones[]>) => {
      state.zones = action.payload;
    },

    setTaskDetailsLoading: (state, action: PayloadAction<boolean>) => {
      state.isTaskDetailsLoading = action.payload;
    },

    setDistrictList: (state, action: PayloadAction<DeliveryAreaList[]>) => {
      state.districtList = action.payload;
    },

    setDistributionFilters: (
      state,
      action: PayloadAction<DistributionFilters | {}>
    ) => {
      state.filters = action.payload;
    },

    setDistributionListLoading: (state, action: PayloadAction<boolean>) => {
      state.distributionListLoading = action.payload;
    },

    setDistributionListNextPage: (state, action: PayloadAction<boolean>) => {
      state.distributionListNextPage = action.payload;
    },

    setDistributionListPage: (state, action: PayloadAction<number>) => {
      state.distributionListPage = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setDeliveryTasks,
  setDeliveryTasksZones,
  setTaskDetailsLoading,
  setDistrictList,
  setDistributionFilters,
  setDistributionListLoading,
  setDistributionListNextPage,
  setDistributionListPage,
} = distributionSlice.actions;

export default distributionSlice.reducer;

export const getDeliveryTasksAction = (resetPage = false) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { activeLocale } = getActiveConfig();
      const { filters, distributionListPage, distributionListNextPage } =
        getState().distribution;
      const page = resetPage ? 1 : distributionListPage + 1;
      // @ts-ignore
      const countryId = filters?.countryId || activeLocale.CountryId;
      // create a local filter object to remove the countryId
      // because we are already passing the countryId in the url

      const filtersCopy = { ...filters, page };
      delete filtersCopy?.countryId;
      let query = "";
      query = "?" + createQuery({ filter: filtersCopy || {} });

      dispatch(setDistributionListLoading(true));
      const { data } = await getDeliveryTasks({ countryId, query });

      const statusGroup = data.data.statusGroups as DistributionStatusGroup[];
      const drivers = (data.included?.drivers || []) as DistributionDriver[];
      const locations = (data.included?.locations ||
        []) as DistributionLocation[];
      const exceptionReasons = (data.included?.exceptionReasons ||
        []) as DistributionExceptions[];

      // loop over statusGroup[i].tasks[j].locationId and get the location details from locations and add to statusGroup[i].tasks[j]
      // loop over statusGroup[i].tasks[j].driverId and get the driver details from drivers and add to statusGroup[i].tasks[j]
      const updatedStatusGroup = statusGroup.map((group) => {
        const updatedTasks = group.tasks.map((task) => {
          let location = null;
          let driver = null;
          let exceptionReasonsFiltered = [];

          // check if locationId is not null
          if (task.locationId && locations.length > 0) {
            location = locations.find((loc) => loc.id === task.locationId);
          }

          // check if driverId is not null
          if (task.driverId && drivers?.length > 0) {
            driver = drivers.find((drv) => drv.id === task.driverId);
          }

          // check if exceptionReasonId is not null
          if (
            task.exceptionReasonsIds &&
            task.exceptionReasonsIds?.length > 0 &&
            exceptionReasons.length > 0
          ) {
            exceptionReasonsFiltered = task.exceptionReasonsIds.map((id) => {
              const reason = exceptionReasons.find(
                (reason) => reason.id === id
              );
              return reason;
            });
          }

          return {
            ...task,
            location,
            driver,
            exceptionReasons: exceptionReasonsFiltered,
          };
        });
        return {
          ...group,
          tasks: updatedTasks,
        };
      });

      if (page > 1 && distributionListNextPage) {
        // we need to append the new data to the existing data
        // so we need to find the status group and append the tasks
        const currentStatusGroups = getState().distribution.statusGroups;
        const statusGroupWithNextPage = currentStatusGroups.map((group) => {
          const groupItem = { ...group };
          const newGroup = updatedStatusGroup.find(
            (g) => g.status === group.status
          );
          if (newGroup) {
            groupItem.tasks = [...groupItem.tasks, ...newGroup.tasks];
          }
          return groupItem;
        });

        const isLastPage = updatedStatusGroup.every(
          (group) => group.tasks.length === 0
        );

        if (isLastPage) {
          dispatch(setDistributionListNextPage(false));
        } else {
          dispatch(setDeliveryTasks(statusGroupWithNextPage));
        }
      } else if (page === 1) {
        dispatch(setDeliveryTasks(updatedStatusGroup));
        dispatch(setDistributionListNextPage(true));
      }

      dispatch(setDistributionListPage(page));
      dispatch(setDistributionListLoading(false));
      dispatch(alertMessage(i18n.t("Delivery tasks fetched"), "success"));
    } catch (err) {
      const message = errorHandler(err);
      dispatch(setDistributionListLoading(false));
      dispatch(alertMessage(message, "error"));
    }
  };
};

export const getDeliveryTaskByIdAction = (id: number) => {
  return async (
    dispatch: AppDispatch
  ): Promise<DistributionTaskDetails | undefined> => {
    try {
      const { activeLocale } = getActiveConfig();
      const countryId = activeLocale.CountryId;
      dispatch(setTaskDetailsLoading(true));
      const { data } = await getDeliveryTaskDetailsById({ countryId, id });
      dispatch(setTaskDetailsLoading(false));
      const details = data.data as DistributionTaskDetails;
      const users =
        (data.included?.users as { id: number; name: string }[]) || [];

      // loop through the events and add the user details
      if (details.events && details.events.length > 0) {
        details.events = details.events.map((event) => {
          const user = users.find((u) => u.id === event.userId);
          let rollBacks = event.rollBacks || null;
          if (rollBacks) {
            const rollbackUser = users.find((u) => u.id === rollBacks.userId);
            if (rollbackUser) {
              rollBacks = {
                ...rollBacks,
                userName: rollbackUser?.name || "",
              };
            }
          }
          return {
            ...event,
            userName: user?.name || "",
            rollBacks,
          };
        });
      }

      return details;
    } catch (err) {
      dispatch(setTaskDetailsLoading(false));
      const message = errorHandler(err);
      dispatch(alertMessage(message, "error"));
    }
  };
};

export const getDistributionDeliveryTaskByIdAction = (id: number) => {
  return async (
    dispatch: AppDispatch
  ): Promise<DistributionDeliveriesInterface | undefined> => {
    try {
      const { activeLocale } = getActiveConfig();
      const countryId = activeLocale.CountryId;
      dispatch(setTaskDetailsLoading(true));
      const { data } = await getDeliveryTaskDetailsById({ countryId, id });
      dispatch(setTaskDetailsLoading(false));
      const details = data.data as DistributionDeliveriesInterface;
      const drivers =
        data.included?.drivers || [];
          return {
            ...details,
            driver:drivers
          };       
      }
     catch (err) {
      dispatch(setTaskDetailsLoading(false));
      const message = errorHandler(err);
      dispatch(alertMessage(message, "error"));
    }
  };
};

export const getDistrictList = () => async (dispatch: AppDispatch) => {
  try {
    const { activeLocale } = getActiveConfig();
    const countryId = activeLocale.CountryId;
    const { data } = await getDeliveryAreaAPI(countryId);
    dispatch(setDistrictList(data.data));
  } catch (error) {
    const message = errorHandler(error);
    dispatch(alertMessage(message, "error"));
  }
};

export const updateDistributionStatus =
  (messageTask: DistributionTask) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    // 1. In order to update the status of the task , we need to move the task from its current status group to the new status group
    // 2. First we need to find the task in the statusGroups array
    // 3. Remove the task from the current status group
    // 4. Add the task to the new status group
    // 5. Update the statusGroups in the store
    // 6. if the current status group is same as the new status group then no need to update the statusGroups
    // 7. Don't do anything if the task is not found in the statusGroups array
    // 8. Don't duplicate the task in the statusGroups array

    const statusGroups = JSON.parse(
      JSON.stringify(getState().distribution.statusGroups)
    );

    try {
      // Find the task in the statusGroups array
      let currentStatusGroupIndex = -1;
      let taskIndex = -1;
      for (let i = 0; i < statusGroups.length; i++) {
        taskIndex = statusGroups[i].tasks.findIndex(
          (task) => task.id === messageTask.id
        );
        if (taskIndex !== -1) {
          currentStatusGroupIndex = i;
          break;
        }
      }

      // If the task is not found, do nothing
      if (currentStatusGroupIndex === -1) {
        return;
      }

      // If the current status group is same as the new status group, do nothing
      if (statusGroups[currentStatusGroupIndex].status === messageTask.status) {
        return;
      }

      // Remove the task from the current status group
      const task = statusGroups[currentStatusGroupIndex].tasks.splice(
        taskIndex,
        1
      )[0];

      // Find the new status group
      const newStatusGroup = statusGroups.find(
        (group) => group.status === messageTask.status
      );

      // Add the task to the new status group, ensuring not to duplicate
      if (
        newStatusGroup &&
        !newStatusGroup.tasks.find((t) => t.id === task.id)
      ) {
        newStatusGroup.tasks.push(task);
      }
      dispatch(setDeliveryTasks(statusGroups));
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
