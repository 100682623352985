// @ts-nocheck
import React from "react";
import Div from "@jumbo/shared/Div";
import Background from "images/background.svg";
import { ThemeProvider, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import { InputWhite } from "app/common/Styles/input";
import { useTranslation } from "react-i18next";

type props = {
  children: React.ReactNode;
  heading: string;
  links: {
    label: string;
    to: string;
  }[];
  form: React.ReactNode;
};

const AuthParent = ({ children, heading, links, form }: props) => {
  const { t } = useTranslation();
  return (
    <Div
      sx={{
        width: "100%",
        height: "100%",
        background: (theme) =>
          `url(${Background}) no-repeat right bottom ,${theme.palette.background.primary} `,
        display: "flex",
      }}
    >
      <Div
        sx={{
          width: 400,
          maxWidth: "100%",
          marginBlock: "auto",
          p: 4,
          marginInlineStart: { lg: 8, sm: 0 },
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        }}
      >
        <Div sx={{ mb: 4 }}>
          <Typography
            variant={"h1"}
            color={"primary.contrastText"}
            fontWeight={500}
            fontSize={36}
            mb={3}
          >
            {t("common.lightFields")}
          </Typography>
          {heading && (
            <Typography
              variant={"h5"}
              color={"primary.contrastText"}
              fontWeight={400}
              mb={3}
            >
              {heading}
            </Typography>
          )}
        </Div>
        <ThemeProvider theme={InputWhite}>{form}</ThemeProvider>
        {children}
        {links?.length > 0 && (
          <Div sx={{ mb: 3, mt: { lg: 6, sm: 2 } }}>
            {links?.map((item) => (
              <Typography
                variant={"body1"}
                mb={1}
                color={"primary.contrastText"}
                key={item.label}
              >
                <Link href={item.to} color={"inherit"} underline={"none"}>
                  {item.label}
                </Link>
              </Typography>
            ))}
          </Div>
        )}
      </Div>
    </Div>
  );
};

export default AuthParent;
