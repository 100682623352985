import { errorHandler } from "app/shared/Service/errorHandler";
import { SET_REFRESH_TOKEN, SET_TOKEN } from "app/utils/constants/auth";
import {
  loginApi,
  signupApi,
  resetPasswordApi,
  recoveryPasswordApi,
  resetPasswordPostApi,
} from "../../services/authService";
import { alertMessage } from "./common";

export const login = (payload: any, cb?: any) => {
  return async (dispatch: any) => {
    try {
      const {
        data: { data },
      } = await loginApi(payload);
      dispatch({
        type: SET_TOKEN,
        payload: data.accessToken,
      });
      dispatch({
        type: SET_REFRESH_TOKEN,
        payload: data.refreshToken,
      });
      cb?.(data);
    } catch (error) {
      cb?.(null, error);
    }
  };
};

export const signup = (payload: any, token: any, cb?: any) => {
  return async (dispatch: any) => {
    try {
      await signupApi(payload, token);
      cb();
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
      cb(null, error);
    }
  };
};

export const recoveryPassword = (payload: any, cb?: any) => {
  return async (dispatch: any) => {
    try {
      await recoveryPasswordApi(payload);
      cb();
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
      cb(null, error);
    }
  };
};

export const resetPassword = (payload: any, token: any, cb?: any) => {
  return async (dispatch: any) => {
    try {
      await resetPasswordApi(payload, token);
      cb();
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
      cb(null, error);
    }
  };
};
export const adminResetPassword = (
  payload: {
    clientId: string;
    password: string;
  },
  cb?: any
) => {
  return async (dispatch: any) => {
    try {
      await resetPasswordPostApi(payload);
      cb();
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
      cb(null, error);
    }
  };
};
