import { DeliveryAreaPayload, productsPayload } from "app/types";
import axios from "./config";

export const getCountriesAPI = () => axios.get(`/Countries`);

export const getZonesAPI = (countryId: number) =>
  axios.get(`/zones?countryId=${countryId}`);

export const postZonesAPI = (payload: { Name: string }) =>
  axios.post(`/zones`, payload);

export const putZonesAPI = (zoneId: number, payload: { Name: string }) =>
  axios.put(`/zones/${zoneId}`, payload);

export const putAreaAPI = (
  governarateId: number,
  districtId: number,
  payload: DeliveryAreaPayload
) => axios.put(`/governates/${governarateId}/Districts/${districtId}`, payload);

export const getCountriesById = (id: number) => axios.get(`/Countries/${id}`);

export const createDeliveryAreaAPI = (
  governateId: number,
  payload: DeliveryAreaPayload
) => axios.post(`/governates/${governateId}/Districts`, payload);

export const getDeliveryAreaAPI = (countryId: number) =>
  axios.get(`/countries/${countryId}/districts`);

export const getGovernateApI = (provinceId: number) =>
  axios.get(`/Provinces/${provinceId}/Governates`);

export const getProvincesApI = (countryId: number) =>
  axios.get(`/Countries/${countryId}/Provinces`);

export const getProductsApI = (
  storeId: number | string,
  branchId: number,
  payload: any
) => axios.get(`/stores/${storeId}/branches/${branchId}/products${payload}`);

export const addStocksApI = (
  storeId: number | string,
  branchId: number,
  productId: number,
  payload: productsPayload
) =>
  axios.post(
    `/stores/${storeId}/branches/${branchId}/products/${productId}/stocks`,
    payload
  );
