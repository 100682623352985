import AuthAxios from "./auth";
import axios from "./config";
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";

const cookies = new Cookies();
export const loginApi = (payload: any) =>
  AuthAxios.post("/authentications", payload);

export const signupApi = (payload: any, token: any) =>
  axios.post(`/registrations`, payload, {
    headers: {
      InvitationToken: token,
    },
  });

export const recoveryPasswordApi = (payload: any) =>
  AuthAxios.post("/authentications/recovery", payload);

export const resetPasswordApi = (payload: any, token: any) => {
  return AuthAxios.post("/authentications/reset", payload, {
    headers: {
      token: token,
    },
  });
};
export const resetPasswordPostApi = (payload:any)=>{
  axios.post("/admin-reset-password", payload);
};
export const getJwtDetails = () => {
  const token = getToken();
  if (!token) {
    return null;
  }
  const decoded = jwt_decode(token);
  return decoded;
};

export const decodeJwt = (token: any) => {
  return jwt_decode(token);
};

export const getCurrentUser = () => {
  return axios.get("/me");
};

export const setToken = (token: any) => {
  if (getToken()) {
    return;
  }
  cookies.set("token", token, { path: "/" });
};

export const getToken = () => {
  return cookies.get("token");
};

export const removeToken = () => {
  cookies.remove("token", { path: "/" });
};
