import { config } from "../../../app/config/main";
import { setToken, removeToken as removeToeknFormStorage } from 'app/services/authService';

export const storeToken = (token) => {
    setToken(token);
    if (!config?.authSetting?.axiosObject)
        throw Error("axiosObject need to be set under authSettings inside app/config/main.js");
    else
        config.authSetting.axiosObject.defaults.headers.common['Authorization'] = 'Bearer ' + token;
};

export const removeToken = () => {
    removeToeknFormStorage()
    if (!config?.authSetting?.axiosObject)
        throw Error("axiosObject need to be set under authSettings inside app/config/main.js");
    else
        delete config.authSetting.axiosObject.defaults.headers.common['Authorization'];
};