import {
  ExpressOrderBranch,
  ExpressOrderItem,
  ExpressOrderSummary,
  expressorderPagingData,
} from "app/types";
import {
  CLEAR_EXPRESS_ORDER_STORE,
  SET_EXPRESS_ORDER_FILTER,
  SET_EXPRESS_ORDER_LIST,
  SET_EXPRESS_ORDER_LOADING,
  SET_EXPRESS_ORDER_MEALS_SUMMARY,
  SET_EXPRESS_ORDER_PAGE,
  SET_EXPRESS_ORDER_STATUS_SUMMARY,
  SET_VIEW_ORDER_SUMMARY,
  NOTIFY_NEW_DATA,
  SET_BRANCH,
  UPDATE_EXPRESS_ORDER_ITEM,
} from "app/utils/constants/expressOrder";

import produce from "immer";

// TODO: @jilsha : Please add the type for the initial state
interface ExpressOrderInitState {
  expressOrderList: ExpressOrderItem[];
  orderStatusSumary: ExpressOrderSummary;
  orderMealsSummary: any;
  viewExpressOrderSummary: any;
  expressOrderPagingData: expressorderPagingData;
  loading: boolean;
  newOrder: boolean;
  filter: any;
  branch: ExpressOrderBranch[];
  branchMapping: any;
}

const initialState: ExpressOrderInitState = {
  expressOrderList: null,
  orderStatusSumary: null,
  orderMealsSummary: null,
  viewExpressOrderSummary: null,
  expressOrderPagingData: null,
  loading: false,
  newOrder: false,
  filter: null,
  branch: null,
  branchMapping: {},
};

export default produce((draft, action) => {
  switch (action.type) {
    case SET_EXPRESS_ORDER_LIST:
      draft.expressOrderList = action.payload;
      break;
    case NOTIFY_NEW_DATA:
      draft.newOrder = action.payload;
      break;
    case SET_BRANCH:
      draft.branch = action.payload;
      draft.branchMapping =
        action.payload?.length > 0
          ? Object.fromEntries(
              action.payload.map((item: any) => [item.id, item])
            )
          : {};
      break;
    case SET_VIEW_ORDER_SUMMARY:
      draft.viewExpressOrderSummary = action.payload;
      break;
    case SET_EXPRESS_ORDER_STATUS_SUMMARY:
      draft.orderStatusSumary = action.payload;
      break;
    case SET_EXPRESS_ORDER_MEALS_SUMMARY:
      draft.orderMealsSummary = action.payload;
      break;
    case SET_EXPRESS_ORDER_PAGE:
      draft.expressOrderPagingData = action.payload;
      break;
    case SET_EXPRESS_ORDER_LOADING:
      draft.loading = action.payload;
      break;
    case SET_EXPRESS_ORDER_FILTER:
      draft.filter = action.payload;
      break;

    case UPDATE_EXPRESS_ORDER_ITEM:
      const index = draft.expressOrderList.findIndex(
        (item) => item.expressOrderId === action.payload.expressOrderId
      );
      const currentStatus = draft.expressOrderList[index]?.status;
      if (index !== -1) {
        draft.expressOrderList[index] = action.payload;
        switch (currentStatus) {
          case "active":
            draft.orderStatusSumary.active--;
            break;
          case "Pending":
            draft.orderStatusSumary.pending--;
            break;
          case "Confirmed":
            draft.orderStatusSumary.confirmed--;
            break;
          case "Preparing":
            draft.orderStatusSumary.preparing--;
            break;
          case "ReadyForDelivery":
            draft.orderStatusSumary.readyForDelivery--;
            break;
          case "AssignedDriver":
            draft.orderStatusSumary.assignedDriver--;
            break;
          case "OutForDelivery":
            draft.orderStatusSumary.outForDelivery--;
            break;
          case "Delivered":
            draft.orderStatusSumary.delivered--;
            break;
          case "DeliveredLate":
            draft.orderStatusSumary.deliveredLate--;
            break;
          case "Cancelled":
            draft.orderStatusSumary.cancelled--;
            break;
          default:
            break;
        }
      } else {
        // If the order is not in the list, add it to the list
        draft.expressOrderList.unshift(action.payload);
      }

      break;
    case CLEAR_EXPRESS_ORDER_STORE:
      return initialState;
    default:
      return draft;
  }
}, initialState);
