import produce from "immer";
import {
  SET_MEALS_DATA,
  SET_MEALS_LOADING,
  SET_MEALS_FILTER,
  CLEAR_MEALS_STORE,
  SET_MEALS_TOKENS,
  SET_MEALS_CATEGORIES,
  SET_DISH_DETAIL,
  SET_DISH_LOCALE_DETAIL,
  SET_RAW_MEAL_DATA,
  SET_PORTIONS,
  SET_RECIPE_DETAIL,
  SET_RECIPE_LOADER,
  SET_DISH_RECIPES,
  SET_KITCHEN_SECTIONS,
  SET_EDIT_RECIPE_DETAILS,
  SET_NEW_RECIPE_ITEMS,
  SET_RECIPE_ITEMS,
  SET_SUBRECIPES,
  SET_SELECTED_SUBRECIPE,
  SET_SELECTED_ITEM,
  ADD_ITEM_TO_ROWID,
  EDITING_SUB_RECIPE,
  SET_ACTIVE_RECIPE_GROUP,
  SET_SELECTED_PORTION,
} from "app/utils/constants/meals";

const initialState = {
  mealData: null,
  loading: false,
  nextToken: null,
  prevToken: null,
  mealsCategories: null,
  filter: null,
  dishDetail: null,
  dishLocaleDetail: null,
  rawMealData: null,
  portions: null,
  recipeDetail: null,
  recipeLoader: null,
  dishRecipes: null,
  kitchenSections: null,
  editRecipeDetail: null,
  recipeNewItems: null,
  recipeItems: null,
  subRecipes: null,
  selectedSubRecipe: null,
  selectedItem: null,
  addItemRowId: null,
  editingSubRecipe: null,
  activeRecipeGroup: null,
  selectedPortion: null,
};

export default produce((draft, action) => {
  switch (action.type) {
    case SET_MEALS_DATA:
      draft.mealData = action.payload;
      break;
    case SET_RAW_MEAL_DATA:
      draft.rawMealData = action.payload;
      break;
    case SET_MEALS_TOKENS:
      draft.nextToken = action.payload.nextToken;
      draft.prevToken = action.payload.prevToken;
      break;
    case SET_MEALS_CATEGORIES:
      draft.mealsCategories = action.payload;
      break;
    case SET_MEALS_LOADING:
      draft.loading = action.payload;
      break;
    case SET_MEALS_FILTER:
      draft.filter = action.payload;
      break;
    case SET_DISH_DETAIL:
      draft.dishDetail = action.payload;
      break;
    case SET_DISH_LOCALE_DETAIL:
      draft.dishLocaleDetail = action.payload;
      break;
    case SET_PORTIONS:
      draft.portions = action.payload;
      break;
    case SET_RECIPE_DETAIL:
      draft.recipeDetail = action.payload;
      break;
    case SET_RECIPE_LOADER:
      draft.recipeLoader = action.payload;
      break;
    case SET_DISH_RECIPES:
      draft.dishRecipes = action.payload;
      break;
    case SET_KITCHEN_SECTIONS:
      draft.kitchenSections = action.payload;
      break;
    case SET_EDIT_RECIPE_DETAILS:
      draft.editRecipeDetail = action.payload;
      break;
    case SET_NEW_RECIPE_ITEMS:
      draft.recipeNewItems = action.payload;
      break;
    case SET_SUBRECIPES:
      draft.subRecipes = action.payload;
      break;
    case SET_RECIPE_ITEMS:
      draft.recipeItems = action.payload;
      break;

    case SET_SELECTED_SUBRECIPE:
      draft.selectedSubRecipe = action.payload;
      break;
    case SET_SELECTED_ITEM:
      draft.selectedItem = action.payload;
      break;
    case ADD_ITEM_TO_ROWID:
      draft.addItemRowId = action.payload;
      break;
    case EDITING_SUB_RECIPE:
      draft.editingSubRecipe = action.payload;
      break;
    case SET_ACTIVE_RECIPE_GROUP:
      draft.activeRecipeGroup = action.payload;
      break;
    case SET_SELECTED_PORTION:
      draft.selectedPortion = action.payload;
      break;

    case CLEAR_MEALS_STORE:
      return initialState;
    default:
      return draft;
  }
}, initialState);
