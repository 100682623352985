import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  routesForAuthenticatedOnly,
  routesForNotAuthenticatedOnly,
} from "../../../app/routes";
import useRoutePathMatch from "@jumbo/hooks/useRoutePathMatch";
import { removeToken, storeToken } from "./authHelpers";
import { config } from "../../../app/config/main";
import { AuthContext } from "@jumbo/components/JumboAuthProvider/JumboAuthContext";
import { getToken } from "app/services/authService";
import { Privileges } from "app/utils/constants/common";
const storedToken = getToken();

const init = () => {
  let authUser = null;

  if (!config?.authSetting) {
    throw Error(
      `You are using JumboAuthProvider but you haven't setup authSetting inside /src/app/config/main.js's config object`
    );
  }

  if (storedToken) {
    storeToken(storedToken); // also sets axios header
  }

  const authUserString = localStorage.getItem("authUser");
  const activeAuthUser = authUserString ? JSON.parse(authUserString) : null;

  return {
    authToken: storedToken ?? null,
    authUser: activeAuthUser || authUser,
    isLoading: false,
    fallbackPath: config.authSetting.fallbackPath,
  };
};

const authReducer = (state, action) => {
  switch (action.type) {
    case "set-auth-data":
      return {
        ...state,
        ...action.payload,
      };

    case "start-loading":
      return {
        ...state,
        isLoading: true,
      };

    case "stop-loading":
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

const JumboAuthProvider = ({ children, ...restProps }) => {
  const [authOptions, setAuthOptions] = React.useReducer(
    authReducer,
    restProps,
    init
  );
  const [logout, setLogout] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticatedRouteOnly = useRoutePathMatch([
    ...routesForAuthenticatedOnly,
  ]);
  const isNotAuthenticatedRouteOnly = useRoutePathMatch([
    ...routesForNotAuthenticatedOnly,
  ]);

  React.useEffect(() => {
    if (logout) {
      removeToken();
      setAuthOptions({
        type: "set-auth-data",
        payload: { authToken: null, authUser: null, isLoading: false },
      });
      setLogout(false);
    }
  }, [logout]);

  const setAuthToken = React.useCallback(async (token) => {
    setAuthOptions({ type: "start-loading" });
    if (!token) {
      setLogout(true);
      return;
    }

    storeToken(token);
    try {
      const authUser = await config?.authSetting?.getAuthUserService();
      if (authUser) {
        setAuthOptions({
          type: "set-auth-data",
          payload: { authToken: token, authUser: authUser, isLoading: false },
        });

        // set the authuser data on localstorage.
        localStorage.setItem("authUser", JSON.stringify(authUser));
        return;
      }
      setLogout(true);
    } catch (error) {
      setLogout(true);
      console.error(error);
    }
  }, []);

  //todo: maybe in next version
  const setRedirectPath = React.useCallback((redirectPath) => {
    setAuthOptions({ type: "set-redirect-path", payload: { redirectPath } });
  }, []);

  const setAuthData = React.useCallback((data) => {
    setAuthOptions({ type: "set-auth-data", payload: data });
  }, []);

  const contextValue = React.useMemo(() => {
    return {
      ...authOptions,
      setAuthData,
      setRedirectPath,
      setAuthToken,
      setAuthOptions,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authOptions]);

  React.useEffect(() => {
    if (!authOptions.authToken && isNotAuthenticatedRouteOnly) {
      return;
    } else if (authOptions.authToken && isNotAuthenticatedRouteOnly) {
      if (
        authOptions.authUser?.data?.privilege === Privileges.cloudKitchenManager ||
        authOptions.authUser?.data?.privilege === Privileges.centralKitchenStaff
      ) {
        navigate("/dashboards/orders");
      } else if (
        authOptions.authUser?.data?.privilege === Privileges.customerServiceAgent ||
        authOptions.authUser?.data?.privilege === Privileges.customerServiceSupervisor ||
        authOptions.authUser?.data?.privilege === Privileges.dietitian
      ) {
        navigate("/dashboards/subscriptions");
      } else if (authOptions.authUser?.data?.privilege === Privileges.logisticManager) {
        navigate("/dashboards/delivery-orders");
      }else if (authOptions.authUser?.data?.privilege === Privileges.centralKitchenManager) {
        navigate("/dashboards/productions");
       } else {
        navigate("/dashboards/menus/catalogs");
      }
    } else if (isAuthenticatedRouteOnly && !authOptions.authToken) {
      navigate(authOptions?.fallbackPath);
    } else if (isAuthenticatedRouteOnly && authOptions.authToken) {
      return;
    } else if (authOptions.authToken) {
      navigate(config.authSetting.redirectNotAuthenticatedPath);
    } else {
      navigate(authOptions?.fallbackPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authOptions.authUser, location]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default JumboAuthProvider;
