import produce from "immer";
import {
  SET_CURRENT_USER,
  SET_TOKEN,
  SET_REFRESH_TOKEN,
} from "app/utils/constants/auth";

const initialState = {
  user: null,
  token: null,
  refreshToken: null,
};

export default produce((draft, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      draft.user = action.payload;
      break;
    case SET_TOKEN:
      draft.token = action.payload;
      break;
    case SET_REFRESH_TOKEN:
      draft.refreshToken = action.payload;
      break;
    default:
      return draft;
  }
}, initialState);
