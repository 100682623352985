export const SET_USER_DETAIL = "SET_USER_DETAIL";
export const SET_PACKAGE_SELECTION = "SET_PACKAGE_SELECTION";
export const SET_BUNDLE_SELECTION = "SET_BUNDLE_SELECTION";
export const SET_BILLING_INFORMATION = "SET_BILLING_INFORMATION";
export const SET_PAYMENT = "SET_PAYMENT";
export const CLEAR_CREATE_SUBSCRIPTION_DATA = "CLEAR_CREATE_SUBSCRIPTION_DATA";
export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";
export const SET_DELIVERY_INFO = "SET_DELIVERY_INFO";
export const SET_ORDER = "SET_ORDER";
export const SET_ORDER_PAYLOAD = "SET_ORDER_PAYLOAD";
export const SET_CHECKOUT_LOADER = "SET_CHECKOUT_LOADER";
export const SET_MANUAL_CHECKOUT_LOADER = "SET_MANUAL_CHECKOUT_LOADER";
export const CLEAR_DATA_ONWARDS = "CLEAR_DATA_ONWARDS";
