export const SET_CATALOG_LOADING = "SET_CATALOG_LOADING";
export const CLEAR_CATALOG_STORE = "CLEAR_CATALOG_STORE";
export const SET_CATALOG_DATA = "SET_CATALOG_DATA";
export const SET_CATALOG_TOKENS = "SET_CATALOG_TOKENS";
export const SET_CATALOG_MENU = "SET_CATALOG_MENU";
export const SET_ACTIVE_CATALOG_ID = "SET_ACTIVE_CATALOG_ID";
export const SET_MENU_SELECTED_DAYS = "SET_MENU_SELECTED_DAYS";
export const SET_CATALOG_ACTIVE_MENU = "SET_CATALOG_ACTIVE_MENU";
export const RESET_MENU_DATA = "RESET_MENU_DATA";
export const SET_CATALOG_MENU_LOADING = "SET_CATALOG_MENU_LOADING";
export const SET_CATALOG_MENU_CREATE = "SET_CATALOG_MENU_CREATE";
export const SET_ACTIVE_MENU_ITEMS = "SET_ACTIVE_MENU_ITEMS";
export const SET_ACTIVE_MENU_ITEMS_LOADER = "SET_ACTIVE_MENU_ITEMS_LOADER";
export const SET_DELETING_ACTIVE_MENU_ITEMS = "SET_DELETING_ACTIVE_MENU_ITEMS";
export const SET_MEAL_TO_DELETE = "SET_MEAL_TO_DELETE";
export const SET_MEAL_TO_DELETE_LOADER = "SET_MEAL_TO_DELETE_LOADER";
export const SET_LIST_GRID_VIEW = "SET_LIST_GRID_VIEW";
