import produce from "immer";
import {
  SET_PRODUCTIONS_DATA,
  SET_PRODUCTIONS_LOADING,
  SET_PRODUCTIONS_FILTER,
  CLEAR_PRODUCTIONS_STORE,
  SET_KITCHEN_SECTION,
} from "app/utils/constants/productions";

const initialState = {
  rawProductionData: null,
  productionData: null,
  portions: null,
  loading: false,
  filter: null,
  kitchenSection: null,
};

export default produce((draft, action) => {
  switch (action.type) {
    case SET_PRODUCTIONS_DATA:
      draft.rawProductionData = action.payload.rawProductionData;
      draft.productionData = action.payload.productionData;
      draft.portions = action.payload.portions;
      break;
    case SET_PRODUCTIONS_LOADING:
      draft.loading = action.payload;
      break;
    case SET_PRODUCTIONS_FILTER:
      draft.filter = action.payload;
      break;
    case SET_KITCHEN_SECTION:
      draft.kitchenSection = action.payload;
      break;
    case CLEAR_PRODUCTIONS_STORE:
      return initialState;
    default:
      return draft;
  }
}, initialState);
