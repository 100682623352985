import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import JumboApp from "@jumbo/components/JumboApp";
import AppLayout from "./AppLayout";
import JumboTheme from "@jumbo/components/JumboTheme";
import AppRoutes from "./AppRoutes";
import { store /*history*/ } from "./redux/store";
import JumboDialog from "@jumbo/components/JumboDialog";
import JumboDialogProvider from "@jumbo/components/JumboDialog/JumboDialogProvider";
import { SnackbarProvider } from "notistack";
import AppProvider from "./AppProvider";
import { config } from "./config/main";
import JumboRTL from "@jumbo/JumboRTL/JumboRTL";
import Div from "@jumbo/shared/Div";
import { CircularProgress } from "@mui/material";
import JumboAuthProvider from "@jumbo/components/JumboAuthProvider";
import Alert from "./layouts/shared/common/alert";
import { I18nextProvider } from "react-i18next";
import i18n from "./config/i18n";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <BrowserRouter /*history={history}*/>
            <AppProvider>
              <JumboApp activeLayout={config.defaultLayout}>
                <JumboTheme init={config.theme}>
                  <JumboRTL>
                    <JumboDialogProvider>
                      <JumboDialog />
                      <SnackbarProvider
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        maxSnack={3}
                      >
                        <JumboAuthProvider>
                          <AppLayout>
                            <Suspense
                              fallback={
                                <Div
                                  sx={{
                                    display: "flex",
                                    minWidth: 0,
                                    alignItems: "center",
                                    alignContent: "center",
                                    height: "100%",
                                  }}
                                >
                                  <CircularProgress
                                    sx={{ m: "-40px auto 0" }}
                                  />
                                </Div>
                              }
                            >
                              <AppRoutes />
                            </Suspense>
                          </AppLayout>
                        </JumboAuthProvider>
                        <Alert />
                      </SnackbarProvider>
                    </JumboDialogProvider>
                  </JumboRTL>
                </JumboTheme>
              </JumboApp>
            </AppProvider>
          </BrowserRouter>
        </Provider>
      </QueryClientProvider>
    </I18nextProvider>
  );
}

export default App;
