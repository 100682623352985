import produce from "immer";
import {
  SET_USER_DETAIL,
  SET_PACKAGE_SELECTION,
  SET_BUNDLE_SELECTION,
  SET_BILLING_INFORMATION,
  SET_PAYMENT,
  CLEAR_CREATE_SUBSCRIPTION_DATA,
  SET_ACTIVE_STEP,
  SET_DELIVERY_INFO,
  SET_ORDER,
  SET_ORDER_PAYLOAD,
  SET_CHECKOUT_LOADER,
  CLEAR_DATA_ONWARDS,
  SET_MANUAL_CHECKOUT_LOADER,
} from "app/utils/constants/createSubscription";

const initialState = {
  userDetail: null,
  packageSelection: null,
  bundleSelection: null,
  deliveryInfo: null,
  billingInfo: null,
  payment: null,
  activeStep: 0,
  order: null,
  orderPayload: null,
  checkoutLoading: false,
  manualCheckoutLoading: false,
};

export default produce((draft, action) => {
  switch (action.type) {
    case SET_USER_DETAIL:
      return { ...draft, userDetail: action.payload };
    case SET_PACKAGE_SELECTION:
      return { ...draft, packageSelection: action.payload };
    case SET_BUNDLE_SELECTION:
      return { ...draft, bundleSelection: action.payload };
    case SET_BILLING_INFORMATION:
      return { ...draft, billingInfo: action.payload };
    case SET_PAYMENT:
      return { ...draft, payment: action.payload };
    case SET_ACTIVE_STEP:
      return { ...draft, activeStep: action.payload };
    case SET_DELIVERY_INFO:
      return { ...draft, deliveryInfo: action.payload };
    case SET_ORDER:
      return { ...draft, order: action.payload };
    case SET_ORDER_PAYLOAD:
      return { ...draft, orderPayload: action.payload };
    case SET_CHECKOUT_LOADER:
      return { ...draft, checkoutLoading: action.payload };
    case SET_MANUAL_CHECKOUT_LOADER:
      return { ...draft, manualCheckoutLoading: action.payload };

    case CLEAR_DATA_ONWARDS:
      const dataList = [
        "userDetail",
        "packageSelection",
        "bundleSelection",
        "deliveryInfo",
        "billingInfo",
        "payment",
        "order",
        "orderPayload",
      ];

      const { startStep, activeStep } = action.payload;
      const index = dataList.indexOf(startStep);
      const newDataList = dataList.slice(index + 1);

      let updatedDraft: any = { ...draft };
      for (const key of newDataList) {
        updatedDraft[key] = null;
      }

      if (activeStep) {
        updatedDraft.activeStep = activeStep;
      }
      return { ...updatedDraft };

    case CLEAR_CREATE_SUBSCRIPTION_DATA:
      return initialState;
    default:
      return draft;
  }
}, initialState);
