import baseAxios from "axios";
import { getToken } from "./authService";
const { REACT_APP_BASE_URL, REACT_APP_ANALYTICS_URL } = process.env;

const axios = baseAxios.create({
  baseURL: REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Application: "dashboard",
    // TODO: jhovel use this accept value later
    // Accept: "application/vnd.lightfields.comp.content.api.json",
    Accept: "application/vnd.lightfields.content.api.json",
    "accept-language": "en",
    "Access-Control-Allow-Origin": "*",
  },
});

//Add a request interceptor
axios?.interceptors.request.use(
  (config) => {
    let token = "";
    if (getToken()) token = getToken();
    if (config.headers && token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//Add a response interceptor
axios?.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
    }
    return Promise.reject(error);
  }
);

export default axios;

const analyticsAxios = baseAxios.create({
  baseURL: REACT_APP_ANALYTICS_URL,
  headers: {
    "Content-Type": "application/json",
    Application: "dashboard",
    Accept: "application/vnd.lightfields.content.api.json",
    "accept-language": "en",
    "Access-Control-Allow-Origin": "*",
  },
});

//Add a request interceptor
analyticsAxios?.interceptors.request.use(
  (config) => {
    let token = "";
    if (getToken()) token = getToken();
    if (config.headers && token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export { analyticsAxios, axios };
